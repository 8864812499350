html {
  font-size: 14px;
}

/* FADE ANIMATION */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* DASHBOARD CAROUSEL SLIDE */
/* Relies on from-left or from-right being present in parent div */
.slide-appear,
.slide-enter {
  opacity: 0;
  z-index: 1;
}
.from-right .slide-enter {
  transform: translateX(750px);
}
.from-left .slide-enter {
  transform: translateX(-750px);
}

.slide-appear-active,
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-out;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
  z-index: 1;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transition: all 300ms ease-out;
}

.from-right .slide-exit.slide-exit-active {
  transform: translateX(-750px);
}
.from-left .slide-exit.slide-exit-active {
  transform: translateX(750px);
}
